import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Arrow } from 'app/components/GalleryCategories/Arrow'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

import { useGlobalState } from './Slider'

export interface Props {
  images: ImageProps[]
  isOpen?: boolean
  title?: string
}

export const Gallery = memo(function Gallery({ images, isOpen, title }: Props) {
  if (!images) {
    return null
  }

  const activeSlider = Number(useGlobalState().get())

  const [details, setDetails] = useState<any>(null)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    controls: false,
    duration: 2000,
    loop: true,
    move(s) {
      setDetails(s.details())
    },
  })

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.positions[idx]
    const x = details.widthOrHeight * -position.distance
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  useEffect(() => {
    if (slider) {
      slider.moveToSlide(activeSlider, 0)
    }

    if (isOpen) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 37:
            slider.prev()
            break
          case 39:
            slider.next()
            break
        }
      })
    }
  }, [slider, activeSlider, isOpen])

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <Carousel ref={sliderRef}>
        {uniq(images).map((item, index) => (
          <Slide key={index} className="keen-slider__slide">
            <Inner style={positionStyle(index)}>
              <Image {...item} />
            </Inner>
          </Slide>
        ))}
      </Carousel>
      {images && images.length > 1 ? (
        <Arrows row>
          <Arrow onClick={(e) => e.stopPropagation() || slider.prev()} />
          <Arrow
            direction="R"
            onClick={(e) => e.stopPropagation() || slider.next()}
          />
        </Arrows>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 2.5rem;
  position: absolute;
  bottom: 4.0625rem;
  left: 5.833vw;
  text-transform: uppercase;
  z-index: 3;
`

const Carousel = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.5)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 3.125rem;
  right: 5.833vw;
  z-index: 3;
`
